<template>
  <b-modal size="md" v-model="computedShowModal" :title="$t('schedule.info_title') + ' - ' + schedule.batch_name" hide-footer @hidden="closeModal">
    <table class="table table-bordered">
      <tr class="p-2">
        <td width="25%">Channel</td>
        <td>
          <i :class="[schedule.channel_icon, schedule.channel_color]"/> {{ schedule.channel_name_str }}
        </td>
      </tr>
      <tr class="p-2">
        <td width="25%">Batch Name</td>
        <td>{{ schedule.batch_name }}</td>
      </tr>
      <tr>
        <td width="25%">Created By</td>
        <td>{{ schedule.creator ? schedule.creator.name : '-' }}</td>
      </tr>
      <tr>
        <td width="25%">Created Date</td>
        <td>{{ schedule.created_at_str }}</td>
      </tr>
      <tr>
        <td width="25%">Schedule Date</td>
        <td>{{ schedule.scheduled_at_str }}</td>
      </tr>
      <tr>
        <td width="25%">Template</td>
        <td>{{ schedule.template_name }} <span style="padding: 6px" class="badge ml-1 badge-secondary"><flag :iso="schedule.flag_icon"/> {{ (schedule.template_language || '-').toUpperCase() }}</span></td>
      </tr>
      <tr>
        <td width="25%">Recipients</td>
        <td>
          {{ schedule.total_recipients }} recipient(s)
        </td>
      </tr>
      <tr>
        <td width="25%">Status</td>
        <td>
          <p class="leading-tight rounded-sm p-1 mt-0 mb-1 cursor-pointer"
            :class="schedule.class">
            {{ schedule.status_str }}
          </p>
        </td>
      </tr>
    </table>
    <div class="d-flex flex-row mt-2 float-right">
      <el-button v-if="schedule.schedule_executed_status == 'pending'" size="small" @click="handlerCancelSchedule" :loading="loading.cancel_schedule" class="btn btn-sm btn-danger">
        {{ $t('schedule.cancel_schedule_btn') }}
      </el-button>
      <el-button size="small" @click="closeModal" type="secondary">{{ $t('general.close') }}</el-button>
    </div>
  </b-modal>
</template>
<script>
import notifApi from '../../../api/notifications';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'ScheduleInfo',
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    schedule: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      show_modal: false,
      loading: {
        cancel_schedule: false,
      },
      relation: {
        am: 'am',
        ar: 'sy',
        az: 'az',
        bn: 'bd',
        be: 'be',
        ca: 'es-ca',
        cs: 'cz',
        de: 'de',
        el: 'gr',
        en: 'gb',
        es: 'es',
        et: 'et',
        fa: 'ir',
        fr: 'fr',
        bg: 'bg',
        ha: 'ng',
        hi: 'in',
        hu: 'hu',
        hy: 'am',
        it: 'it',
        ja: 'jp',
        jv: 'id',
        km: 'km',
        ko: 'kr',
        lv: 'lv',
        mr: 'in',
        ms: 'my',
        pl: 'pl',
        pt: 'pt',
        ro: 'ro',
        ru: 'ru',
        sw: 'ke',
        ta: 'lk',
        te: 'in',
        th: 'th',
        tr: 'tr',
        uk: 'ua',
        uz: 'uz',
        vi: 'vn',
        zh: 'cn',
      },
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    computedShowModal: {
      get() {
        return this.show_modal;
      },
      set(val) {
        this.show_modal = val;
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit('onClose');
    },
    handlerCancelSchedule() {
      this.$confirm(this.$t('schedule.confirm_cancel_schedule'), this.$t('general.confirmation'), {
        type: 'warning',
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: (action, instance, cb) => {
          if (action === 'confirm') {
            this.doCancelSchedule();
          }
          cb();
        },
      });
    },
    async doCancelSchedule() {
      this.loading.cancel_schedule = true;
      await notifApi.cancelSchedule(this.activeWorkspace._id, this.schedule.id).then(() => {
        this.$message({
          type: this.$t('success'),
          message: this.$t('schedule.success_cancel_schedule'),
        });
        this.$parent.getList();
        this.closeModal();
      }).catch((err) => popupErrorMessages(err));
      this.loading.cancel_schedule = false;
    },
  },
  watch: {
    showModal() {
      this.show_modal = this.showModal;
    },
  },
};
</script>
